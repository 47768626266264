import { List, ListItem } from "@chakra-ui/core";
import { Link as ReachLink } from "gatsby";
import { navigate } from "gatsby";
import axios from "axios";
import _get from "lodash.get";
import _mapValues from "lodash.mapvalues";
import { Box, FormField, Heading, Icon, Link, Stack, Text } from "pws-design-system/design-system/";
import queryString from "query-string";
import React, { useState } from "react";
import { object, string } from "yup";
import { formDataToJson, isBrowser } from "../../../../utils";
import { useMessages } from "../../../common/hooks/useMessages";
import { Form } from "../common/";
import Layout from "../common/Layout";
import { PasswordField } from "../register/PasswordField";
import { ParamValidated } from "../../../common/components/param-validated";

const paramSchema = object().shape({
  id: string().required(),
  token: string().required()
});

const passwordTests = {
  length: (password: string) => password.length >= 8,
  lowercase: (password: string) => /(?=.*[a-z])/.test(password),
  uppercase: (password: string) => /(?=.*[A-Z])/.test(password),
  numberOrSpecialCharacter: (password: string) => {
    return /(?=.*\d)/.test(password) || /[-+_!@#$%^&*.,?]/.test(password);
  }
} as any;

const ValidationIcon = props => {
  const { valid } = props;
  if (valid) {
    return <Icon name="check-circle" color="green.400" />;
  }
  return <Icon name="check-circle" color="gray.300" />;
};

const errorMap = {
  validation_error:
    "Your password did not meet validation requirements. It cannot be the same as your username. Please try again.",
  invalid:
    "Your password did not meet validation requirements. It cannot be the same as your username. Please try again."
};

export default function ForgotPasswordPage(): React.ReactElement {
  const [passwordValidation, setPasswordValidation] = useState(
    _mapValues(passwordTests, () => false)
  );

  const [isViewingPassword, setIsViewingPassword] = useState(false);

  if (isBrowser() === false) {
    return null;
  }

  const queryParams = queryString.parse(location.search);

  try {
    paramSchema.validateSync(queryParams);
  } catch (e) {
    navigate("/forgot-password", {
      state: {
        flash: `The token was invalid or expired. Please repeat the update process.`
      }
    });
    return;
  }

  const messages = useMessages();

  function handleSuccess(authToken: string) {
    window.location.assign(
      `${process.env.GATSBY_PROD_URL}${process.env.GATSBY_STATION_LIST_URL}?authToken=${authToken}&toast=passwordUpdated`
    );
  }

  const handlePasswordChange = (event: React.SyntheticEvent) => {
    const value = (event.target as HTMLInputElement).value;
    const validation = _mapValues(passwordTests, (test: (password: string) => boolean) =>
      test(value)
    );
    setPasswordValidation(validation);
  };

  const handleSubmit = async (
    event,
    setErrors,
    send,
    action,
    withCredentials,
    successHandler,
    errorHandler
  ) => {
    const target = event.target as HTMLFormElement;
    const formData = new FormData(target);
    setErrors(null);
    send("submitting");

    try {
      const data = { ...queryParams, ...formDataToJson(formData, false) };
      const response = await axios({
        url: action,
        method: "POST",
        withCredentials: true,
        headers: {
          "Content-Type": "application/json"
        },
        data
      });

      send("success");
      const authToken = _get(response, "data.response.token", false);
      if (authToken) {
        handleSuccess(authToken);
      } else {
        throw new Error();
      }
    } catch (error) {
      const code = _get(error, "response.data.error.code");
      const message = _get(error, "response.data.error.message");
      // if (code === "validation_error") {
      //   const context = _get(error, "response.data.error");
      //   console.log(context);
      // }

      switch (code) {
        case "password_update_token_invalid":
          navigate("/forgot-password", {
            state: {
              flash: `Your password reset application was invalid or expired. Please repeat the update process.`
            }
          });
        case "validation_error":
          const validationError = _get(error, "response.data.error.description.body[0].message");
          if (validationError) {
            setErrors(validationError);
          } else {
            setErrors("An unknown error occured.");
          }
          break;
        default:
          if (message) {
            setErrors(message);
          } else {
            setErrors("An unknown error occured.");
          }
      }
      send("errored");
    }
  };

  return (
    <Layout metaTitle={"Update Your Password"} metaDescription={""}>
      <Form
        HeadingSlot={
          <>
            <Heading variant="headline">Update Password</Heading>
            <Text variant="caption1">
              <Link as={ReachLink} to="/login" fontWeight="normal">
                Return to log in.
              </Link>
            </Text>
          </>
        }
        action={`${process.env.GATSBY_PWS_API_URL}${process.env.GATSBY_UPDATE_PASSWORD_URL}`}
        successHandler={handleSuccess}
        submitHandler={handleSubmit}
      >
        <Stack spacing={4} isInline>
          <Box>
            <PasswordField />
          </Box>
          <FormField
            id="password2"
            name="password2"
            label="Confirm Password"
            field={{
              type: isViewingPassword ? "text" : "password",
              variant: "flushed"
            }}
            required
          />
        </Stack>
      </Form>
    </Layout>
  );
}
