import React from "react";
import UpdatePasswordPage from "../react/pages/authentication/update-password";
import { Providers } from "../react/common/layouts/Layout";

export default function UpdatePasswordGatsbyEntry() {
  return (
    <Providers>
      <UpdatePasswordPage />
    </Providers>
  );
}
